import { Grid, GridItem, Heading, Text } from "@chakra-ui/react";
import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import Footer from "../components/MeltwaterFooter";
import {
  ImportScriptMeshkraftAr,
  ImportScriptMeshkraftViewer,
  useQuery,
} from "../utils/helpers";

function Product() {
  const slug = "meltwater-trophy-2022";
  let query = useQuery();

  document.title = "artlabs x meltwater";

  const token = "l1hls8LjOSHFwrEpF11VRQHNUxtNLqM9mtsLXEUHZF";
  const arButton = query.get("arButton");

  let productRef = useRef(null);

  useEffect(() => {
    const myElement = document.querySelector("meshkraft-viewer");

    if (myElement) {
      myElement.addEventListener("product-fetched", (e) => {
        productRef.current = e;
      });
    }
    return () => {
      if (myElement) {
        myElement.removeEventListener("product-fetched", (e) => {});
      }
    };
  }, []);

  return (
    <>
      {token && (
        <ImportScriptMeshkraftViewer
          resourceUrl={
            "https://cdn.jsdelivr.net/npm/meshkraft-viewer/dist/meshkraft-viewer.min.js"
          }
          meshkraftKey={token}
        />
      )}

      <Main sku={slug} product={productRef.current} />

      <Footer slug={slug} arButton={arButton} />
    </>
  );
}

const Main = ({ sku, product }) => {
  let query = useQuery();
  const disableCart = query.get("disableCart");

  let viewerConfig = `{"exposure": 1.0, "ar-modes": "scene-viewer webxr quick-look fallback", "ar-scale": "auto", "field-of-view": "62deg", "max-field-of-view": "65deg", "min-field-of-view": "60deg", "show-banner": ${!disableCart}}`;

  return (
    <>
      <Grid
        position="absolute"
        backgroundColor="white"
        w="100vw"
        h="80vh"
        pt="10vh"
      >
        <GridItem
          position="absolute"
          top="10vh"
          left={{ base: "0", lg: "5rem" }}
          right={{ base: "0", lg: null }}
          textAlign="center"
        >
          <Heading as="h1">{product?.name}</Heading>
          <Text fontSize="1.5rem">{product?.category}</Text>
        </GridItem>
        <meshkraft-viewer
          as={GridItem}
          sku={sku}
          config={viewerConfig}
          height="80vh"
        />
      </Grid>
    </>
  );
};

export default Product;
